'use client';

import { captureException } from '@sentry/nextjs';
import NextError from 'next/error';
import { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { $configGamePageStore, ConfigGamePage } from '@/root/app/[locale]/games/[slug]/model';
import { aiTrackServerError } from '@/root/app/error';
export default function GlobalError({
  error
}: {
  error: Error;
}) {
  const configGamePage = useUnit($configGamePageStore) as ConfigGamePage;
  useEffect(() => {
    (async () => {
      console.error(error);
      captureException(error);
      await aiTrackServerError(configGamePage, error);
    })();
  }, [error, configGamePage]);
  return <html data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
			<body>
				{/* This is the default Next.js error component but it doesn't allow omitting the statusCode property yet. */}
				<NextError statusCode={500} data-sentry-element="NextError" data-sentry-source-file="global-error.tsx" />
			</body>
		</html>;
}